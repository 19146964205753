import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import { isEmpty } from '@service/utils/validation';
import { M12, R12, R14 } from '@styles/global';
import { addComma } from '@service/utils/number';
import {
  BorderLine,
  FlexStyle,
  GreyBox,
  GreyLine,
  Grid,
  Title,
  Value,
} from '@styles/carFaxResultStyle';
import Skeleton from '@components/common/Skeleton';
import { ContentProps } from './CarDetailContent';
import useUserStore from '@store/userStore';
import {
  useCheckGrades,
  useCheckInformation,
  useCheckOption,
  useCheckRegister,
  useInfoOptions,
  useCheckSpec,
} from '@service/query/useSearch';

const CarOptions = ({
  carNo,
  ownerName,
  transactionId,
  isMobile,
}: ContentProps) => {
  const [selectedGradeId, setSelectedGradeId] = useState<string>('');
  const { token } = useUserStore();

  // ---- 소유자명이 없는 경우 ----  infoData -> infoOptionData
  const { data: infoData, isRefetching: isInfoLoading } = useCheckInformation({
    carNo,
  });

  const { data: infoOptionData, isRefetching: isInfoOptionLoading } =
    useInfoOptions({
      firstRegistrationDate: infoData?.firstRegistrationDate,
      gradeId: selectedGradeId,
    });
  // --------------

  // ---- 소유자명이 있는 경우 ---- regData -> gradesData -> optionData
  const { data: regData, isRefetching: isRegLoading } = useCheckRegister({
    carNo,
    ownerName,
    transactionId,
    token,
  });

  const isHasRegData = !isEmpty(regData);

  const { data: gradesData, isRefetching: isGradeLoading } = useCheckGrades({
    carNo,
    ownerName,
    transactionId,
    token,
    isHasRegData,
  });

  const { data: optionData, isRefetching: isOptionLoading } = useCheckOption({
    carNo,
    ownerName,
    transactionId,
    token,
    gradeId: selectedGradeId,
  });
  // ----------------


  // --- api 업데이트
  const { data: specData, isRefetching, isError } = useCheckSpec({
    carNo,
    ownerName,
    transactionId,
    token,
  });

  const options = specData?.carAddOptions;

  useEffect(() => {
    if (infoData?.gradeList?.length > 0) {
      setSelectedGradeId(infoData?.gradeList[0]?.gradeId);
    }
  }, [infoData?.gradeList]);

  useEffect(() => {
    if (gradesData?.length > 0) {
      setSelectedGradeId(gradesData[0]?.gradeId);
    }
  }, [gradesData]);

  const isLoading = !isEmpty(ownerName)
    ? isGradeLoading || isRegLoading || isOptionLoading
    : isInfoLoading || isInfoOptionLoading;

  const isHasOption = !isEmpty(ownerName)
    ? !isEmpty(optionData)
    : !isEmpty(infoOptionData);

  if (isLoading)
    return (
      <CarOption>
        <Title>옵션 정보</Title>
        <Skeleton width='100%' height={100} />
      </CarOption>
    );

  if (!isLoading && !isHasOption)
    return (
      <CarOption>
        <Title>옵션 정보</Title>
        <BorderLine />
        <CarOption>
          <GreyLine>
            <p className='date'> 옵션 정보를 불러올 수 없습니다.</p>
          </GreyLine>
        </CarOption>
      </CarOption>
    );

  return (
    <CarOption>
      <GradeOptionTitleRow>
        <GradeOptionTitle style={{ marginRight: 12 }}>옵션 정보</GradeOptionTitle>
      </GradeOptionTitleRow>
      {/* 소유주 없는 경우 infoData 사용 */}
      {!isInfoOptionLoading && !isEmpty(infoOptionData) && (
        <>
          {!isMobile && (
            <>
              <BorderLine />
              <GreyBox>출고시 옵션</GreyBox>
              {/* {infoData?.selectedOptions?.options?.length > 0 ? ( */}
              {specData?.length > 0 ? (
                <OptionValue>
                  {/* {infoData.selectedOptions.options.map((el) => { */}
                  {specData.map((el) => {
                    return (
                      <ChargedOption key={el}>
                        <p>{el.optionName}</p>
                      </ChargedOption>
                    );
                  })}
                </OptionValue>
              ) : (
                <OptionValue>
                  <p className='none'>조회된 출고시 옵션이 없습니다.</p>
                </OptionValue>
              )}
              <BorderLine />

              <GreyBox>선택 옵션</GreyBox>
              <OptionValue>
                {isEmpty(infoOptionData?.chargedOptions) ||
                infoOptionData?.chargedOptions?.length === 0 ? (
                  <p className='none'>조회된 유료 옵션이 없습니다.</p>
                ) : (
                  <>
                    {infoOptionData?.chargedOptions.map((opt) => {
                      return (
                        <ChargedOption>
                          <p>- {opt.optName}</p>
                          <p>
                            {opt?.optPrice?.includes(',')
                              ? opt?.optPrice
                              : addComma(opt?.optPrice)}{' '}
                            원
                          </p>
                        </ChargedOption>
                      );
                    })}
                  </>
                )}
              </OptionValue>
              <BorderLine />
              <Grid>
                <div>
                  <GreyBox>외장</GreyBox>
                  <OptionValue>
                    {infoOptionData?.exteriorOptions?.length > 0 &&
                      infoOptionData?.exteriorOptions?.map((opt) => {
                        if (opt.optType === 'O') return <p>{opt.optName}</p>;
                      })}
                  </OptionValue>
                </div>
                <div>
                  <GreyBox>내장</GreyBox>
                  <OptionValue>
                    {infoOptionData?.interiorOptions?.length > 0 &&
                      infoOptionData?.interiorOptions.map((opt) => {
                        if (opt.optType === 'O') return <p>{opt.optName}</p>;
                      })}
                  </OptionValue>
                </div>
                <div>
                  <GreyBox>안전</GreyBox>
                  <OptionValue>
                    {infoOptionData?.safeOptions?.length > 0 &&
                      infoOptionData?.safeOptions.map((opt) => {
                        if (opt.optType === 'O') return <p>{opt.optName}</p>;
                      })}
                  </OptionValue>
                </div>
                <div>
                  <GreyBox>편의</GreyBox>
                  <OptionValue>
                    {infoOptionData?.convenienceOptions?.length > 0 &&
                      infoOptionData?.convenienceOptions.map((opt) => {
                        if (opt.optType === 'O') return <p>{opt.optName}</p>;
                      })}
                  </OptionValue>
                </div>
              </Grid>
            </>
          )}
          {isMobile && (
            <>
              <BorderLine />
              <GreyBox>출고시 옵션</GreyBox>
              {infoData?.selectedOptions?.options?.length > 0 ? (
                <OptionValue>
                  {infoData.selectedOptions.options.map((el) => {
                    return (
                      <ChargedOption key={el}>
                        <p>{el}</p>
                      </ChargedOption>
                    );
                  })}
                </OptionValue>
              ) : (
                <OptionValue>
                  <p className='none'>조회된 출고시 옵션이 없습니다.</p>
                </OptionValue>
              )}
              <BorderLine />
              <GreyBox>선택 품목</GreyBox>
              <OptionValue>
                {isEmpty(infoOptionData?.chargedOptions) ||
                infoOptionData?.chargedOptions?.length === 0 ? (
                  <p className='none'>조회된 유료 옵션이 없습니다.</p>
                ) : (
                  <>
                    {infoOptionData?.chargedOptions.map((opt) => {
                      return (
                        <ChargedOption>
                          <p>- {opt.optName}</p>
                          <p>
                            {opt?.optPrice?.includes(',')
                              ? opt?.optPrice
                              : addComma(opt?.optPrice)}{' '}
                            원
                          </p>
                        </ChargedOption>
                      );
                    })}
                  </>
                )}
              </OptionValue>
              <BorderLine />
              <div>
                <GreyBox>외장</GreyBox>
                <OptionValue>
                  {infoOptionData?.exteriorOptions?.length > 0 &&
                    infoOptionData?.exteriorOptions?.map((opt) => {
                      if (opt.optType === 'O') return <p>{opt.optName}</p>;
                    })}
                </OptionValue>
              </div>
              <BorderLine />
              <div>
                <GreyBox>내장</GreyBox>
                <OptionValue>
                  {infoOptionData?.interiorOptions?.length > 0 &&
                    infoOptionData?.interiorOptions.map((opt) => {
                      if (opt.optType === 'O') return <p>{opt.optName}</p>;
                    })}
                </OptionValue>
              </div>
              <BorderLine />
              <div>
                <GreyBox>안전</GreyBox>
                <OptionValue>
                  {infoOptionData?.safeOptions?.length > 0 &&
                    infoOptionData?.safeOptions.map((opt) => {
                      if (opt.optType === 'O') return <p>{opt.optName}</p>;
                    })}
                </OptionValue>
              </div>
              <BorderLine />
              <div>
                <GreyBox>편의</GreyBox>
                <OptionValue>
                  {infoOptionData?.convenienceOptions?.length > 0 &&
                    infoOptionData?.convenienceOptions.map((opt) => {
                      if (opt.optType === 'O') return <p>{opt.optName}</p>;
                    })}
                </OptionValue>
              </div>
            </>
          )}
        </>
      )}
      {/* 소유주 있는 경우 optionData 사용 */}
      {!isOptionLoading && !isEmpty(optionData) && (
        <>
          {!isMobile && (
            <>
              <BorderLine />
              <GreyBox>출고시 옵션</GreyBox>
              {regData?.selectedOptions?.options?.length > 0 ? (
                <OptionValue>
                  {regData?.selectedOptions.options.map((el) => {
                    return (
                      <ChargedOption key={el}>
                        <p>{el}</p>
                      </ChargedOption>
                    );
                  })}
                </OptionValue>
              ) : (
                <OptionValue>
                  <p className='none'>조회된 출고시 옵션이 없습니다.</p>
                </OptionValue>
              )}
              <BorderLine />
              <GreyBox>선택 옵션</GreyBox>
              <OptionValue>
                {isEmpty(optionData?.chargedOptions) ||
                optionData?.chargedOptions?.length === 0 ? (
                  <p className='none'>조회된 유료 옵션이 없습니다.</p>
                ) : (
                  <>
                    {optionData?.chargedOptions.map((opt) => {
                      return (
                        <ChargedOption>
                          <p>- {opt.optName}</p>
                          <p>
                            {opt?.optPrice?.includes(',')
                              ? opt?.optPrice
                              : addComma(opt?.optPrice)}{' '}
                            원
                          </p>
                        </ChargedOption>
                      );
                    })}
                  </>
                )}
              </OptionValue>
              <Grid>
                <div>
                  <GreyBox>외장</GreyBox>
                  <OptionValue>
                    {optionData?.exteriorOptions?.length > 0 &&
                      optionData?.exteriorOptions?.map((opt) => {
                        if (opt.optType === 'O') return <p>{opt.optName}</p>;
                      })}
                  </OptionValue>
                </div>
                <div>
                  <GreyBox>내장</GreyBox>
                  <OptionValue>
                    {optionData?.interiorOptions?.length > 0 &&
                      optionData?.interiorOptions.map((opt) => {
                        if (opt.optType === 'O') return <p>{opt.optName}</p>;
                      })}
                  </OptionValue>
                </div>
                <div>
                  <GreyBox>안전</GreyBox>
                  <OptionValue>
                    {optionData?.safeOptions?.length > 0 &&
                      optionData?.safeOptions.map((opt) => {
                        if (opt.optType === 'O') return <p>{opt.optName}</p>;
                      })}
                  </OptionValue>
                </div>
                <div>
                  <GreyBox>편의</GreyBox>
                  <OptionValue>
                    {optionData?.convenienceOptions?.length > 0 &&
                      optionData?.convenienceOptions.map((opt) => {
                        if (opt.optType === 'O') return <p>{opt.optName}</p>;
                      })}
                  </OptionValue>
                </div>
              </Grid>
            </>
          )}
          {isMobile && (
            <>
              <BorderLine />
              <GreyBox>출고시 옵션</GreyBox>
              {regData?.selectedOptions?.options?.length > 0 ? (
                <OptionValue>
                  {regData?.selectedOptions?.options.map((el) => {
                    return (
                      <ChargedOption key={el}>
                        <p>{el}</p>
                      </ChargedOption>
                    );
                  })}
                </OptionValue>
              ) : (
                <OptionValue>
                  <p className='none'>조회된 출고시 옵션이 없습니다.</p>
                </OptionValue>
              )}
              <BorderLine />
              <GreyBox>선택 품목</GreyBox>
              <OptionValue>
                {isEmpty(optionData?.chargedOptions) ||
                optionData?.chargedOptions?.length === 0 ? (
                  <p className='none'>조회된 유료 옵션이 없습니다.</p>
                ) : (
                  <>
                    {optionData?.chargedOptions.map((opt) => {
                      return (
                        <ChargedOption>
                          <p>- {opt.optName}</p>
                          <p>
                            {opt?.optPrice?.includes(',')
                              ? opt?.optPrice
                              : addComma(opt?.optPrice)}{' '}
                            원
                          </p>
                        </ChargedOption>
                      );
                    })}
                  </>
                )}
              </OptionValue>
              <div>
                <GreyBox>외장</GreyBox>
                <OptionValue>
                  {optionData?.exteriorOptions?.length > 0 &&
                    optionData?.exteriorOptions?.map((opt) => {
                      if (opt.optType === 'O') return <p>{opt.optName}</p>;
                    })}
                </OptionValue>
              </div>
              <BorderLine />
              <div>
                <GreyBox>내장</GreyBox>
                <OptionValue>
                  {optionData?.interiorOptions?.length > 0 &&
                    optionData?.interiorOptions.map((opt) => {
                      if (opt.optType === 'O') return <p>{opt.optName}</p>;
                    })}
                </OptionValue>
              </div>
              <BorderLine />
              <div>
                <GreyBox>안전</GreyBox>
                <OptionValue>
                  {optionData?.safeOptions?.length > 0 &&
                    optionData?.safeOptions.map((opt) => {
                      if (opt.optType === 'O') return <p>{opt.optName}</p>;
                    })}
                </OptionValue>
              </div>
              <BorderLine />
              <div>
                <GreyBox>편의</GreyBox>
                <OptionValue>
                  {optionData?.convenienceOptions?.length > 0 &&
                    optionData?.convenienceOptions.map((opt) => {
                      if (opt.optType === 'O') return <p>{opt.optName}</p>;
                    })}
                </OptionValue>
              </div>
            </>
          )}
        </>
      )}
      <BorderLine />
    </CarOption>
  );
};

export default CarOptions;

const CarOption = styled.div`
  margin-bottom: 48px;
`;

const GradeOptionTitle = styled(Title)`
  margin: 0;
`;

const GradeOptionTitleRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 12px;
`;

const InitialOptionBox = styled.div`
  padding: 33px;
  width: 100%;
  background-color: ${({ theme }) => theme.base.grey100};
  ${FlexStyle}
  justify-content: center;
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const SelectOption = styled.div`
  cursor: pointer;
  ${FlexStyle}
  padding: 6px 12px;
  border: ${({ theme, focused }) =>
    `1px solid ${focused ? theme.base.black2 : theme.base.grey600}`};
  border-radius: 4px;

  .title {
    ${R14}
    color:${({ theme, focused }) =>
      focused ? theme.base.black2 : theme.base.grey600};
    margin-right: 8px;
  }
`;

const ChargedOption = styled.div`
  width: 100%;
  ${FlexStyle};
  justify-content: space-between;

  p {
    ${R12}
    line-height: 240%;
    color: ${({ theme }) => theme.base.black2};
  }

  p:nth-child(1) {
    text-align: left;
    max-width: calc(100% - 100px);
  }
`;

export const ArrowUp = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  transform: ${({ isUp }) => `rotate(${isUp ? 0 : '-180deg'} )`};
`;

const OptionValue = styled(Value)`
  flex-direction: column;

  p {
    ${R12}
    line-height: 240%;
    text-align: center;
  }

  .none {
    ${M12}
    font-size: 11px;
    color: ${({ theme }) => theme.base.grey400};
  }
`;
