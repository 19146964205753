import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from '@service/utils/validation';
import media from '@styles/responsive';

interface HTMLRenderProps {
  width: number;
  src: string;
  click?: () => void;
  onChange?: () => void;
}

const iframe = (src: string) => {
  return {
    __html: `<iframe src=${src} id='tab' width="100%" height="100%"><base target="_blank"/></iframe>`,
  };
};

const HTMLRender = ({ width, src, onChange, click }: HTMLRenderProps) => {
  useEffect(() => {
    const listener = window.addEventListener('blur', () => {
      if (document.activeElement === document.querySelector('iframe')) {
        // iframe상의 클릭이벤트 인식
        click?.();
        document.querySelector('iframe')?.addEventListener('load', () => {
          // iframe내에 다른 링크로 이동해서 load가 일어난 경우
          onChange?.();
        });
      }
      window.removeEventListener('blur', listener);
    });
  }, []);

  if (isEmpty(src)) return <></>;
  if (!isEmpty(src))
    return (
      <HTMLWrapper
        style={{
          width,
          height: '100vh',
        }}
        dangerouslySetInnerHTML={iframe(src)}
      />
    );
};

export default HTMLRender;

const HTMLWrapper = styled.div`
  iframe {
    border: none;
  }

  .doc-content {
    padding: 0;
    margin: 0;
  }
`;
