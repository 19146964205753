import React, { useRef, useState } from 'react';
import Image from 'next/image';
import styled, { css } from 'styled-components';

import { BorderLine, FlexStyle } from '@styles/carFaxResultStyle';
import { B11, M12, MAX_WIDTH, R11 } from '@styles/global';
import ARROW_UP from '@public/icon/arrow-up.png';
import Modal from '@components/common/Modal';
import HTMLRender from '@components/common/HTMLRender';
import { useTerms } from '@service/query/useUser';
import { ArrowUp, SelectOption, SelectWrapper } from './CarOptions';
import TermsVersinoOption from './TermsVersionOption';
import { getWindowDimension } from '@service/utils/getWindowDimestion';

const MainCompanyCS = ({ isMobile = false }) => {
  const { width } = getWindowDimension();
  const serviceModalRef = useRef<HTMLDivElement>(null);
  const privacyModalRef = useRef<HTMLDivElement>(null);

  const [isDropVisible, setIsDropVisible] = useState<boolean>(false);
  const [selectedVersion, setSelectedVersion] = useState<number>(1);
  const [selectedUrl, setSelectedUrl] = useState<string>('');
  const [isPageChanged, setIsPageChanged] = useState<boolean>(false);

  const { data } = useTerms();

  const selectVersion = (version: number, url: string) => {
    setSelectedVersion(version);
    setSelectedUrl(url);
    setIsDropVisible(false);
  };

  const resetVersion = () => {
    setIsDropVisible(false);
    setSelectedUrl('');
    setSelectedVersion(1);
    setIsPageChanged(false);
  };

  const openAgreement = () => {
    serviceModalRef.current.open();
  };

  const openPrivacy = () => {
    privacyModalRef.current.open();
  };

  const onIframChange = () => {
    if (!isPageChanged) setIsPageChanged(true);
  };

  return (
    <>
      <Container isMobile={isMobile}>
        <BorderLine />

        <ContentWrapper>
          <Content isMobile={isMobile}>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 40 }}>
                <p>(주)겟차 / 정유철</p>
                <p>경기도 수원시 권선구 평동로 79번길 45 SKV1 모터스 603호</p>
                <p>사업자 등록번호 : 243-87-00137</p>
                <p>통신판매업 신고번호 : 2015-서울강남-02617</p>
                <p>ⓒ getcha all rights reserved.</p>
              </div>
              <div>
                <h5>고객센터</h5>
                <p className="large">1800 - 0456 / support@getcha.kr</p>
                <p>
                  평일 : 10:00 - 17:00 | 점심시간 : 12:30 - 13:30 (주말&공휴일
                  제외)
                </p>
              </div>
            </div>
            <Top isMobile={isMobile}>
              <Tag onClick={openAgreement}>이용약관</Tag>
              <Tag onClick={openPrivacy}>개인정보 처리방침</Tag>
            </Top>
          </Content>
        </ContentWrapper>
      </Container>

      <Modal
        ref={serviceModalRef}
        isShowTopBtn
        fullHeight
        leftButtonPressed={resetVersion}
      >
        <ModalContent width={isMobile ? (width > 760 ? 760 : width) : 760}>
          <Version>
            <SelectWrapper>
              <SelectOption
                onClick={() => setIsDropVisible(true)}
                focused={isDropVisible}
              >
                <p className="title">
                  VER{' '}
                  {selectedVersion
                    ? selectedVersion
                    : data?.agreement[0]?.version}
                </p>
                <ArrowUp isUp={isDropVisible}>
                  <Image alt="arrow" layout="fill" src={ARROW_UP} />
                </ArrowUp>
              </SelectOption>

              {isDropVisible && (
                <TermsVersinoOption
                  list={data?.agreement}
                  selected={selectedVersion}
                  select={selectVersion}
                  close={() => setIsDropVisible(false)}
                />
              )}
            </SelectWrapper>
          </Version>
          <HTMLRender
            width={isMobile ? (width > 720 ? 720 : width - 40) : 720}
            src={selectedUrl ? selectedUrl : data?.agreement[0]?.url}
            click={() => setIsDropVisible(false)}
          />
        </ModalContent>
      </Modal>

      <Modal
        ref={privacyModalRef}
        isShowTopBtn
        fullHeight
        leftButtonPressed={resetVersion}
      >
        <ModalContent width={isMobile ? (width > 760 ? 760 : width) : 760}>
          {!isPageChanged && (
            <Version>
              <SelectWrapper>
                <SelectOption
                  onClick={() => setIsDropVisible(true)}
                  focused={isDropVisible}
                >
                  <p className="title">
                    VER{' '}
                    {selectedVersion
                      ? selectedVersion
                      : data?.privacy[0]?.version}
                  </p>
                  <ArrowUp isUp={isDropVisible}>
                    <Image alt="arrow" layout="fill" src={ARROW_UP} />
                  </ArrowUp>
                </SelectOption>

                {isDropVisible && (
                  <TermsVersinoOption
                    list={data?.privacy}
                    selected={selectedVersion}
                    select={selectVersion}
                    close={() => setIsDropVisible(false)}
                  />
                )}
              </SelectWrapper>
            </Version>
          )}
          <HTMLRender
            width={isMobile ? (width > 720 ? 720 : width - 40) : 720}
            src={selectedUrl ? selectedUrl : data?.privacy[0]?.url}
            click={() => setIsDropVisible(false)}
            onChange={onIframChange}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default MainCompanyCS;

const Container = styled.div`
  width: 100%;
  position: ${({ isMobile }) => (isMobile ? 'static' : 'fixed')};
  left: 0;
  bottom: 0;
  background-color: #f4f4f4;
  z-index: 1;

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: static;
    `}
`;

const Top = styled.div`
  ${FlexStyle}
  justify-content: flex-end;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 20px;
    `}
`;

const Tag = styled.div`
  border-radius: 26px;
  background-color: #ebebeb;
  ${M12}
  padding:8px 12px;
  margin-right: 6px;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
  background-color: #f4f4f4;
`;

const Content = styled.div`
  ${FlexStyle}
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0 32px 0;

  p {
    ${R11}
    line-height:150%;
    text-align: left;
    color: #a4a4a4;
  }

  .large {
    ${M12}
    font-size:14px;
    line-height: 150%;
    color: #a4a4a4;
  }

  h5 {
    ${B11}
    font-size:12px;
    line-height: 150%;
    margin-bottom: 4px;
    color: #a4a4a4;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
      padding: 20px;
    `}
`;

const ModalContent = styled.div`
  max-width: ${({ width }) => width}px;
`;

export const Version = styled.div`
  ${FlexStyle}
  justify-content: flex-end;
`;
