import React, { useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { BorderLine, FlexStyle, GreyBox, Value } from '@styles/carFaxResultStyle';
import { B11, B14, B32, M11, M12, MAX_WIDTH, R12, R14 } from '@styles/global';
import useUserStore from '@store/userStore';
import { isEmpty } from '@service/utils/validation';
import Modal from '@components/common/Modal';
import { useUserInfo } from '@service/query/useUser';
import Toast from '@components/common/Toast';
import { ErrorWrapper, Message } from '@pages/result';

const Header = () => {
  const router = useRouter();
  const carNo: string = router.query?.no;

  const toastRef = useRef<HTMLInputElement>(null);
  const chargePopupRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLInputElement>(null);

  const {
    name = '',
    token = '',
    remainTicketCount = 0,
    reset,
    saveUser,
    type,
  } = useUserStore();

  const {
    data: userData,
    error: userInfoError,
    isError: isUserInfoError,
  } = useUserInfo({
    carNo,
    token,
    isResult: router.pathname === '/result',
    successFn: (res) => {
      // console.log('헤더에서 유저', res);
      if (!isEmpty(res)) saveUser(res);
    },
  });

  const isLoggedOn = !isEmpty(token);

  const logout = () => {
    reset();
    useUserStore.persist.clearStorage();
    router.push('/');
  };

  const openChargePopup = () => {
    chargePopupRef.current?.open();

    window.dataLayer = window?.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('event', 'carfax_charge_click', {
      event_label: '카팩스 충전하기 클릭',
      from: 'web',
    });
  };

  const copyEmail = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText('support@getcha.kr');
    } else {
      document.execCommand('copy', true, 'support@getcha.kr');
    }
    toastRef.current?.open();
  };

  const isShort = remainTicketCount <= 3;

  useEffect(() => {
    if (isUserInfoError) {
      modalRef.current?.open();
      reset();
    }
  }, [isUserInfoError, userInfoError]);

  return (
    <>
      <Container>
        <Apart>
          {isLoggedOn ? (
            <div className='flex content'>
              <h5>안녕하세요, {name}님</h5>{' '}
              {/* <div className='text'>
                <span style={{ marginRight: 5 }}> 잔여 조회건수 </span>
                <div
                  className={isShort ? 'red' : 'green'}
                  style={{ position: 'relative' }}
                >
                  {type === 'GETCHA' && '무제한'}
                  {type !== 'GETCHA' && (
                    <>
                      {remainTicketCount}회
                      {remainTicketCount <= 3 && (
                        <ShortTooltipWrapper>
                          <ShortTooltip>
                            잔여 조회 건수가 {remainTicketCount}회 남았습니다. 미리
                            충전하세요!
                          </ShortTooltip>
                          <ShortPoint />
                        </ShortTooltipWrapper>
                      )}
                    </>
                  )}
                </div>
              </div> */}
              {/* <Charge onClick={openChargePopup}>
                <div>
                  <p>충전하기</p>
                </div>
              </Charge> */}
            </div>
          ) : (
            <div />
          )}
          {isLoggedOn ? (
            <Login onClick={logout}>
              <Logout>로그아웃</Logout>
            </Login>
          ) : (
            <Login>
              <Btn onClick={() => router.push('/login')}>로그인</Btn>
              {/* <Divider /> */}
              {/* <Btn onClick={() => router.push('/signup')}>회원가입</Btn> */}
              {/* <TooltipWrapper>
                <Tooltip>3 Free</Tooltip>
                <Point />
              </TooltipWrapper> */}
            </Login>
          )}
        </Apart>
      </Container>

      <Modal ref={chargePopupRef} isShowTopBtn>
        <ModalContent>
          <h2>유료 서비스 충전 안내</h2>
          <h6 style={{ textAlign: 'center' }}>
            조회권 구매는 고객센터를 통해서만 가능합니다.
          </h6>

          <Title>고객센터</Title>
          <BorderLine />
          <FirstGrid>
            <div>
              <ModalGreyBox>전화번호</ModalGreyBox>
              <ModalValue>
                <p>1800-0456</p>
              </ModalValue>
            </div>

            <div>
              <ModalGreyBox>이메일 주소</ModalGreyBox>
              <ModalValue>
                <div>support@getcha.kr</div>
                <Copy onClick={copyEmail}>복사하기</Copy>
              </ModalValue>
            </div>
            <div>
              <ModalGreyBox>영업시간</ModalGreyBox>
              <ModalValue>
                <p>
                  평일 : 10:00 - 17:00 • 점심시간 : 12:30 - 13:30 (주말&공휴일
                  제외)
                </p>
              </ModalValue>
            </div>
          </FirstGrid>
          <BorderLine />

          <Title>상품 안내</Title>
          <div className='modal' style={{ marginBottom: 11 }}>
            <p>1. 상품 가격은 부가가치세가 포함된 가격입니다.</p>
            <p>
              2. 조회권은 다른 사람에게 양도할 수 없으며, 양도로 인해 발생하는
              불이익은 구매자가 책임지셔야 합니다.
            </p>
            <p>
              3. 구매자의 환불기준은 하단의 ‘환불 안내'와 같으며, 이용자가 서비스를
              이용한 후에는 그 해당분에 대하여는 환불을 요구할 수 없습니다.
            </p>
          </div>

          <BorderLine />
          <SecondGrid>
            <div>
              <ModalGreyBox>조회권</ModalGreyBox>
              <ModalValue>
                <p>10회</p>
              </ModalValue>
            </div>

            <div>
              <ModalGreyBox>금액 (VAT포함)</ModalGreyBox>
              <ModalValue>
                <p>17,000원</p>
              </ModalValue>
            </div>
          </SecondGrid>
          <BorderLine />

          <Title>환불 안내</Title>
          <div className='modal'>
            <p>
              1. 이용자가 구매한 이용권의 미사용분에 대해 환불을 요구할 경우
              환불수수료에 해당하는 금액을 공제하고 환불할 수 있습니다.
            </p>
            <br />

            <p>2. 환불금액 계산 : 결제금액 - (① * 건당정보이용료 + ② + ③)</p>
            <p style={{ marginLeft: 10 }}>① 사용 건수(보너스 포함)</p>
            <p style={{ marginLeft: 10 }}>② 결제수수료</p>
            <p style={{ marginLeft: 10 }}>③ 송금수수료(은행에 따라 실비적용)</p>
            <p style={{ marginLeft: 10 }}>※ 건당정보이용료 : 1,700원</p>
            <br />
            <p>
              3. 회사는 구매자 본인 명의의 금융계좌로 입금하는 방법으로 이를
              환불합니다.
            </p>
          </div>
        </ModalContent>
      </Modal>

      <Toast message='이메일 주소를 복사했습니다.' icon='info' ref={toastRef} />

      {isUserInfoError && (
        <Modal
          ref={modalRef}
          leftButtonTitle='확인'
          leftButtonPressed={() => {
            router.replace('/login');
          }}
        >
          <ErrorWrapper>
            <Message>
              {userInfoError?.status !== 500 &&
              !isEmpty(userInfoError?.error?.message) ? (
                userInfoError.error.message
              ) : (
                <>
                  일시적인 오류로 서버와 연결이 끊겼습니다. <br />
                  오류가 계속되면 고객센터로 문의해 주세요. <br />
                  (고객센터 : 1800-0456)
                </>
              )}
            </Message>
          </ErrorWrapper>
        </Modal>
      )}
    </>
  );
};
export default Header;

const Container = styled.header`
  height: 72px;
  width: 100%;
  ${FlexStyle}
  background-color: #555555;
  justify-content: center;
  margin-bottom: 72px;
`;

const Apart = styled.div`
  width: 100%;
  ${FlexStyle}
  justify-content: space-between;
  padding: 0 60px;
  height: 72px;

  .flex,
  .text {
    ${FlexStyle}

    h5 {
      ${B14}
      color:${({ theme }) => theme.base.white};
      margin-right: 10px;
    }
  }

  p,
  .text > span {
    ${R14}
    color:${({ theme }) => theme.base.white};
  }

  .green {
    ${B14}
    color:#04D200;
  }

  .red {
    ${B14}
    position:relative;
    color: ${({ theme }) => theme.primary};
  }
`;

const Charge = styled.button`
  cursor: pointer;
  width: fit-content;
  height: 42px;
  ${FlexStyle}
  justify-content: center;
  padding: 0 6px;
  background-color: transparent;

  div {
    padding: 4px 8px;
    border: ${({ theme }) => `1px solid ${theme.base.white}`};
    border-radius: 12px;
    background-color: ${({ theme }) => theme.base.black2};

    p {
      ${M12}
      color:${({ theme }) => theme.base.white};
    }
  }
`;

const Login = styled.div`
  ${FlexStyle}
`;

const Btn = styled.button`
  cursor: pointer;
  padding: 14px;
  ${B14}
  color:${({ theme }) => theme.base.white};
  background-color: #555555;
`;

const Logout = styled(Btn)`
  ${B14}
  color:${({ theme }) => theme.base.grey400};
`;

const Divider = styled.div`
  width: 1px;
  height: 18px;
  margin: 12px 0;
  background: #6a6a6a;
`;

const TooltipWrapper = styled.div`
  position: relative;
  width: 48px;
  height: fit-content;
  bottom: -25px;
  height: 18px;
  right: 72px;
`;

const Tooltip = styled.div`
  background-color: #04d200;
  padding: 4px 8px;
  border-radius: 11px;
  position: absolute;
  z-index: 1;

  ${B11}
  color:${({ theme }) => theme.base.white};
  font-size: 10px;
`;

const Point = styled.div`
  background-color: #04d200;
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
  position: absolute;
  top: -3px;
  left: 10px;
`;

const ShortTooltipWrapper = styled(TooltipWrapper)`
  width: 223px;
  left: 0;
  position: absolute;
`;

const ShortTooltip = styled(Tooltip)`
  background-color: ${({ theme }) => theme.primary};
`;

const ShortPoint = styled(Point)`
  background-color: ${({ theme }) => theme.primary};
`;

const ModalContent = styled.div`
  max-width: 760px;
  padding: 24px 10px 20px 10px;

  h2 {
    ${B32}
    color:${({ theme }) => theme.base.black1};
    margin-bottom: 16px;
    text-align: center;
  }

  h6 {
    ${B11}
    font-size:12px;
    color: #202020;
  }

  .modal {
    p {
      ${R12}
      line-height:150%;
    }
  }
`;

const Title = styled.h6`
  margin-bottom: 13px;
  margin-top: 40px;
`;

const FirstGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-column-gap: 1px;
`;

const SecondGrid = styled(FirstGrid)`
  grid-template-columns: 1fr 1fr;
`;

const ModalGreyBox = styled(GreyBox)`
  ${M11}
  font-size:10px;
  padding: 12px;
`;

const ModalValue = styled(Value)`
  padding: 0;
  ${FlexStyle}
  ${M11}
  font-size:10px;
  width: 100%;

  p {
    ${M11}
    font-size: 10px;
    padding: 12px 0;
  }
`;

const Copy = styled.button`
  ${M11}
  font-size:10px;
  color: ${({ theme }) => theme.base.grey600};
  padding: 12px 0 12px 10px;
  background: transparent;
`;
