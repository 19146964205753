import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from '@service/utils/validation';
import { B14, R14 } from '@styles/global';
import { useOutsideClick } from '@service/hooks/useOutsideClick';
import { TERMS_INFO } from '@ts-types/auth';

interface TermsVersionOptionProps {
  list: TERMS_INFO[];
  selected: number;
  select: (arg: number, arg2: string) => void;
  close: () => void;
}

const TermsVersionOption = ({
  list,
  selected,
  select,
  close,
}: TermsVersionOptionProps) => {
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => {
    close();
  });

  if (isEmpty(list) || list?.length === 0)
    return <Container ref={dropdownRef} />;

  if (list?.length > 0)
    return (
      <Container ref={dropdownRef}>
        {list?.map((item) => {
          return (
            <Option
              key={item.version}
              isActive={item.version === selected}
              onClick={() => {
                select(item.version, item.url);
              }}
            >
              VER {item.version}
            </Option>
          );
        })}
      </Container>
    );
};

export default TermsVersionOption;

const Container = styled.div`
  top: 34px;
  left: 0;
  position: absolute;
  width: 88px;
  max-height: 88px;
  padding: 0 21px 0 14px;
  background: #ffffff;
  border: ${({ theme }) => `1px solid ${theme.base.lineGrey}`};
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #acacac;
  }
`;

const Option = styled.p`
  cursor: pointer;
  ${R14}
  color: ${({ theme }) => theme.base.grey400};
  text-align: left;

  ${({ isActive }) =>
    isActive &&
    css`
      ${B14}
      color: ${({ theme }) => theme.base.black2};
    `}
  line-height:32px;
`;
