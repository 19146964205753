import { TERMS, USER_INFO } from '@api/ApiPath';
import { getTerms, getUserInfo } from '@api/Auth';
import { isEmpty } from '@service/utils/validation';
import { useQuery } from '@tanstack/react-query';
import {
  SIGN_UP_RESPONSE,
  TERMS_RESPONSE,
  USER_INFO_REQUEST,
} from '@ts-types/auth';
import { AxiosError } from 'axios';

/**
 * 유저정보 조회
 */
const useUserInfo = ({
  token,
  carNo,
  isResult,
  successFn,
}: USER_INFO_REQUEST) => {
  const { data, isLoading, isError, error } = useQuery<
    SIGN_UP_RESPONSE,
    AxiosError
  >([USER_INFO, token], () => getUserInfo({ token }), {
    retry: 1,
    staleTime: 100,
    refetchOnReconnect: true,
    enabled: isResult ? !isEmpty(carNo) && !isEmpty(token) : !isEmpty(token),
    onSuccess: (res) => {
      successFn?.(res?.result);
    },
  });

  return {
    data: data?.result,
    isLoading,
    isError,
    error: error?.response?.data,
  };
};

/**
 * 약관 조회
 */

const useTerms = () => {
  const { data, isLoading, isError, error } = useQuery<
    TERMS_RESPONSE,
    AxiosError
  >([TERMS], () => getTerms(), {
    staleTime: 3600000,
  });
  return {
    data: data?.result,
    isLoading,
    isError,
    error: error?.response?.data,
  };
};

export { useUserInfo, useTerms };
